<template>
  <div
    v-loading="loading"
    :class="[
      'flex',
      'flex-col',
      'w-full',
      'h-full',
      { 'justify-between': !noFooter }
    ]"
    ref="wrapper"
    tabindex="0"
    @keyup.esc="$emit('onClose')"
  >
    <header
      v-if="!noHeader"
      :class="['relative', 'p-5', 'pb-4', ...headerClasses]"
    >
      <div class="flex items-center justify-between">
        <span :class="[...titleClasses, twTitleClass, 'font-bold']">{{
          title
        }}</span>
        <div>
          <HistoryIcon
            v-if="showHistory"
            class="cursor-pointer tool-icon mr-3"
            @click.native="$emit('onShowHistory')"
          />
          <CloseIcon
            class="cursor-pointer tool-icon"
            @click.native="$emit('onClose')"
          />
        </div>
      </div>
      <div>
        <slot name="subtitle"></slot>
      </div>
    </header>
    <template v-else>
      <CloseIcon
        class="cursor-pointer tool-icon closer-outer"
        @click.native="$emit('onClose')"
      />
    </template>
    <div
      :class="[
        'overflow-auto overflow-x-hidden',
        'w-full',
        'pt-4',
        'p-5',
        'max-h-600',
        ...contentStyles
      ]"
    >
      <slot></slot>
    </div>
    <footer
      v-if="!noFooter"
      :class="['flex', 'gap-3', 'p-5', 'relative', ...footerClasses]"
    >
      <el-button
        v-if="isCancel"
        class="objects-footer__cancel border-0 bg-roseWhite text-red-brightDelight rounded-lg font-semibold w-full flex-1 text-base"
        @click="$emit('onClose')"
      >
        {{ $t('cancel') }}
      </el-button>
      <el-button
        class="objects-footer__cancel border-0 bg-blue-200 text-white rounded-lg font-semibold w-full flex-auto text-base"
        :disabled="isDisabled"
        @click="$emit('onSubmit')"
      >
        {{ submitText }}
      </el-button>
    </footer>
  </div>
</template>
<script setup>
import { ref, onMounted, computed } from 'vue'

import HistoryIcon from '../assets/icons/history.vue'
import CloseIcon from '../assets/icons/close.vue'

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  contentStyles: {
    type: Array,
    default: () => []
  },
  headerClasses: {
    type: Array,
    default: () => []
  },
  footerClasses: {
    type: Array,
    default: () => []
  },
  titleClasses: {
    type: Array,
    default: () => []
  },
  titleSize: {
    type: String,
    default: '32'
  },
  showHistory: {
    type: Boolean,
    default: false
  },
  isCancel: {
    type: Boolean,
    default: false
  },
  submitText: {
    type: String,
    default: ''
  },
  loading: {
    type: Boolean,
    default: false
  },
  noFooter: {
    type: Boolean,
    default: false
  },
  noHeader: {
    type: Boolean,
    default: false
  },
  isDisabled: {
    type: Boolean,
    default: false
  }
})

const wrapper = ref(null)

const twTitleClass = computed(() => {
  return props.titleSize === '24' ? 'text-24' : 'text-32'
})

onMounted(() => {
  wrapper.value.focus()
  wrapper.value.style.outline = 'none'
})
</script>

<style lang="scss">
.closer-outer {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(32px, -32px);
}
</style>
