var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"wrapper",class:[
    'flex',
    'flex-col',
    'w-full',
    'h-full',
    { 'justify-between': !_vm.noFooter }
  ],attrs:{"tabindex":"0"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.$emit('onClose')}}},[(!_vm.noHeader)?_c('header',{class:['relative', 'p-5', 'pb-4', ..._vm.headerClasses]},[_c('div',{staticClass:"flex items-center justify-between"},[_c('span',{class:[..._vm.titleClasses, _setup.twTitleClass, 'font-bold']},[_vm._v(_vm._s(_vm.title))]),_c('div',[(_vm.showHistory)?_c(_setup.HistoryIcon,{staticClass:"cursor-pointer tool-icon mr-3",nativeOn:{"click":function($event){return _vm.$emit('onShowHistory')}}}):_vm._e(),_c(_setup.CloseIcon,{staticClass:"cursor-pointer tool-icon",nativeOn:{"click":function($event){return _vm.$emit('onClose')}}})],1)]),_c('div',[_vm._t("subtitle")],2)]):[_c(_setup.CloseIcon,{staticClass:"cursor-pointer tool-icon closer-outer",nativeOn:{"click":function($event){return _vm.$emit('onClose')}}})],_c('div',{class:[
      'overflow-auto overflow-x-hidden',
      'w-full',
      'pt-4',
      'p-5',
      'max-h-600',
      ..._vm.contentStyles
    ]},[_vm._t("default")],2),(!_vm.noFooter)?_c('footer',{class:['flex', 'gap-3', 'p-5', 'relative', ..._vm.footerClasses]},[(_vm.isCancel)?_c('el-button',{staticClass:"objects-footer__cancel border-0 bg-roseWhite text-red-brightDelight rounded-lg font-semibold w-full flex-1 text-base",on:{"click":function($event){return _vm.$emit('onClose')}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]):_vm._e(),_c('el-button',{staticClass:"objects-footer__cancel border-0 bg-blue-200 text-white rounded-lg font-semibold w-full flex-auto text-base",attrs:{"disabled":_vm.isDisabled},on:{"click":function($event){return _vm.$emit('onSubmit')}}},[_vm._v(" "+_vm._s(_vm.submitText)+" ")])],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }